<template>
    <div>
        <st-filters-pagination
            stateModule="issuedDocument/list"
            :filters="filters"
            :total="total"
            @change="doFilter"
            :header="$t('ISSUED_DOCUMENT.LIST.TITLE')"
        >
            <template #filters-toolbar>
                <slot name="toolbar"></slot>
            </template>
            <slot></slot>
        </st-filters-pagination>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import { FilterSchema } from '@/shared/form/filter-schema';
    import { IssuedDocumentModel } from '@/modules/issued-document/issued-document-model';

    const { fields } = IssuedDocumentModel;

    const filterSchema = new FilterSchema([
        fields.issuer_user_id,
        fields.document_name,
        fields.issued_year,
        fields.issued_date,
        fields.beneficiary_full_names,
        fields.document_series,
    ]);
    export default {
        name: 'IssuedDocumentListFilter',
        data() {
            return {
                filters: filterSchema.fields,
                currentPage: 1,
            };
        },
        computed: {
            ...mapGetters({
               total: 'issuedDocument/list/total',
            }),
        },
        methods: {
            ...mapActions({
                doFetch: 'issuedDocument/list/doFetch',
            }),
            refresh() {
                this.doFilter(this.params);
            },
            async doFilter(params) {
                this.params = params;
                this.doFetch(params);
            },
        },

    };
</script>
